import { Tooltip, Wrap } from '@chakra-ui/react';
import React from 'react';
import { FaCircleInfo } from 'react-icons/fa6';

interface InfoToolTipProps {
  children: React.ReactNode;
  text: string;
  iconStyle?: React.CSSProperties;
  offset?: [number, number];
}

const DEFAULT_MAIN_AXIS_OFFSET = 0;
const DEFAULT_CROSS_AXIS_OFFSET = 15;
const DEFAULT_OFFSET: [number, number] = [DEFAULT_MAIN_AXIS_OFFSET, DEFAULT_CROSS_AXIS_OFFSET];
const DEFAULT_ICON_STYLE: React.CSSProperties = { position: 'relative', top: '-8px' };

const WithInfoToolTip = ({
  children,
  text,
  iconStyle = DEFAULT_ICON_STYLE,
  offset = DEFAULT_OFFSET,
}: InfoToolTipProps): React.JSX.Element => {
  return (
    <Wrap>
      {children}
      <Tooltip label={text} hasArrow={true} placement={'right'} offset={offset}>
        {/* Note: Do not remove this span. Icons from react-icons do not accept a forwardRef which is utilized
         by Chakra tooltip for positioning. Without this span, the icon will not be positioned correctly. Please see
         https://v2.chakra-ui.com/docs/components/tooltip/usage#with-an-icon for more information.
         */}
        <span>
          <FaCircleInfo size={'.8em'} color={'gray'} style={iconStyle} />
        </span>
      </Tooltip>
    </Wrap>
  );
};

export default WithInfoToolTip;
