import { CellContext } from '@tanstack/react-table';
import React, { CSSProperties } from 'react';

import APLTableCell from '@/components/available-preview-list/apl-table-cell/APLTableCell';
import { ColorCell, UniqueId } from '@/types/ui.types';

interface CellFactoryProps<T> {
  cellContext: CellContext<T, unknown>;
  text: string;
  actionIcon?: React.ReactNode;
  actionIconClickHandler?: (id: UniqueId) => void;
  cellContentInjector?: (item: CellContext<T, unknown>) => React.JSX.Element;
  className?: string;
  clickHandler?: (id: UniqueId) => void;
  injectedContentOverridesText?: boolean;
  isSelected?: boolean;
  style?: CSSProperties;
}

export const cellFactory = <
  T extends {
    id: UniqueId;
  } & Partial<ColorCell>,
>(
  cellProps: CellFactoryProps<T>,
) => {
  // eslint-disable-next-line react/prop-types
  const {
    actionIcon,
    actionIconClickHandler,
    cellContext,
    className,
    clickHandler,
    cellContentInjector = undefined,
    injectedContentOverridesText = false,
    isSelected = false,
    style,
    text,
  } = cellProps;

  // eslint-disable-next-line react/prop-types
  if (!cellContext.row?.original) return null;

  return (
    <APLTableCell
      actionIconClickHandler={
        actionIconClickHandler ? () => actionIconClickHandler(cellContext.row.original.id) : undefined
      }
      className={className}
      color={cellContext.row.original.color}
      colorText={cellContext.row.original.colorText}
      injectedCellContent={cellContentInjector && cellContentInjector(cellContext)}
      icon={actionIcon ?? undefined}
      injectedContentOverridesText={injectedContentOverridesText}
      isSelected={isSelected}
      onClick={clickHandler ? () => clickHandler(cellContext.row.original.id) : undefined}
      style={style ?? {}}
      text={text}
    />
  );
};
