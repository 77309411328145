/* eslint-disable no-magic-numbers */
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

type PageSizeOption = {
  value: number;
  testingId: string;
};

const BLOCK_LENGTH_DAYS = [1, 2, 3, 4, 5, 6, 7];
const BLOCK_LENGTH_WEEKS = [1, 2, 3, 4];
const DEFAULT_BLOCK_LENGTH_WEEKS = 1;
const DEFAULT_DEBOUNCE_TIME_MS = 500;
const DEFAULT_TABLE_ROW_COUNT = 10;
const HOUR_IN_MILLISECONDS = 3600 * 1000;
const MINIMUM_VIEW_NAME_LENGTH = 3;
const PAGINATION_PAGE_SIZE_OPTIONS: PageSizeOption[] = [
  {
    testingId: ELEMENT_DATA_TEST_IDS.PAGE_SIZE_OF_TEN,
    value: 10,
  },
  {
    testingId: ELEMENT_DATA_TEST_IDS.PAGE_SIZE_OF_TWENTY,
    value: 20,
  },
  {
    testingId: ELEMENT_DATA_TEST_IDS.PAGE_SIZE_OF_THIRTY,
    value: 30,
  },
  {
    testingId: ELEMENT_DATA_TEST_IDS.PAGE_SIZE_OF_FORTY,
    value: 40,
  },
  {
    testingId: ELEMENT_DATA_TEST_IDS.PAGE_SIZE_OF_FIFTY,
    value: 50,
  },
];
const TWO_MINUTES_IN_MILLISECONDS = 120 * 1000;

const UIConfig = {
  BLOCK_LENGTH_DAYS,
  BLOCK_LENGTH_WEEKS,
  DEFAULT_BLOCK_LENGTH_WEEKS,
  DEFAULT_DEBOUNCE_TIME_MS,
  DEFAULT_TABLE_ROW_COUNT,
  HOUR_IN_MILLISECONDS,
  MINIMUM_VIEW_NAME_LENGTH,
  PAGINATION_PAGE_SIZE_OPTIONS,
  TWO_MINUTES_IN_MILLISECONDS,
};

export default UIConfig;
