/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, ButtonGroup, Flex, Link, Text, useToast } from '@chakra-ui/react';
import { ColumnDef, ColumnSort, createColumnHelper, SortingState } from '@tanstack/react-table';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

import { useGetViewsListQuery, useLazyGetViewAccessQuery, ViewOrderBy } from '@/API/views.api';
import PaginatedTableControlled from '@/components/paginated-table-controlled/PaginatedTableControlled';
import PaginatedTableSkeleton from '@/components/skeletons/paginated-table-skeleton/PaginatedTableSkeleton';
import { paginationDefault, ToastTypes } from '@/constants/defaults';
import { SORTING_ORDER } from '@/constants/ui';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setViewPageRequestViewAccessModalOpen } from '@/store/slices/viewPage.slice';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';
import View, { ViewListItem } from '@/types/view.types';
import { lbBaseUrl, openViewInNewTab } from '@/utils/url';

const DEFAULT_SORTING: SortingState = [
  {
    desc: false,
    id: 'name',
  },
];

interface ViewsListViewProps {
  readonly isLoading: boolean;
  readonly selectedDepartmentIds: number[];
  readonly selectedTemplateIds: number[];
  readonly setSelectedViewId: (viewId: number) => void;
  readonly toggleConfirmCloneModal: () => void;
  readonly toggleConfirmDeleteModal: () => void;
  readonly toggleViewAccessDrawer: () => void;
  readonly toggleViewEditorDrawer: () => void;
  readonly viewNameFilter: string;
}

const ViewsListView = (props: ViewsListViewProps): React.JSX.Element => {
  const {
    isLoading,
    selectedDepartmentIds,
    selectedTemplateIds,
    setSelectedViewId,
    toggleConfirmCloneModal,
    toggleConfirmDeleteModal,
    toggleViewAccessDrawer,
    toggleViewEditorDrawer,
    viewNameFilter,
  } = props;
  const [pageNumber, setPageNumber] = React.useState(paginationDefault.PageNumber);
  const [pageSize, setPageSize] = React.useState(paginationDefault.PageSize);
  const [sortingState, setSortingState] = React.useState<SortingState>(DEFAULT_SORTING);

  const { publicViewsData } = useAppSelector((state) => state.viewPage);

  const [checkAccess] = useLazyGetViewAccessQuery();

  const getSortingStateById = (columnId: string): ColumnSort | undefined =>
    sortingState.find((col) => col.id === columnId);

  const setSortingStateById = (columnId: string, desc: boolean | undefined): void => {
    // Sorting for only one column at a time
    const sorting = desc !== undefined ? [{ desc, id: columnId }] : [];

    setSortingState(sorting);
  };

  const getSortingOrder = (currentValue: boolean | undefined): SORTING_ORDER => {
    if (currentValue === undefined) {
      return SORTING_ORDER.NONE;
    }

    return currentValue ? SORTING_ORDER.DESC : SORTING_ORDER.ASC;
  };

  // Column name we are ordering by
  const getOrderByColumn = (): ViewOrderBy => {
    const viewNameSort = getSortingOrder(getSortingStateById('name')?.desc);
    const lastModifiedSort = getSortingOrder(getSortingStateById('lastModified')?.desc);
    const publicViewSort = getSortingOrder(getSortingStateById('publicViewId')?.desc);

    if (viewNameSort !== SORTING_ORDER.NONE) {
      return ViewOrderBy.ViewName;
    }

    if (lastModifiedSort !== SORTING_ORDER.NONE) {
      return ViewOrderBy.LastModified;
    }

    if (publicViewSort !== SORTING_ORDER.NONE) {
      return ViewOrderBy.PublicView;
    }

    return ViewOrderBy.None;
  };

  const getIsAscending = (): boolean => {
    const viewNameSort = getSortingOrder(getSortingStateById('name')?.desc);
    const lastModifiedSort = getSortingOrder(getSortingStateById('lastModified')?.desc);
    const publicViewSort = getSortingOrder(getSortingStateById('publicViewId')?.desc);

    return (
      viewNameSort === SORTING_ORDER.ASC ||
      lastModifiedSort === SORTING_ORDER.ASC ||
      publicViewSort === SORTING_ORDER.ASC
    );
  };

  const {
    pagination,
    viewsList = [],
    isLoading: isViewsListLoading,
    isFetching: isFetchingViewsList,
    isError: isViewsListError,
  } = useGetViewsListQuery(
    {
      departmentList: selectedDepartmentIds,
      filter: viewNameFilter,
      isAscending: getIsAscending(),
      orderBy: getOrderByColumn(),
      pagination: { ...paginationDefault, PageNumber: pageNumber, PageSize: pageSize },
      templateList: selectedTemplateIds,
    },
    {
      selectFromResult: ({ data, isLoading, isFetching, isError }) => {
        return {
          isError,
          isFetching,
          isLoading,
          pagination: data?.pagination ?? paginationDefault,
          viewsList: data?.viewsList,
        };
      },
    },
  );

  const [pageIndex, setPageIndex] = React.useState(paginationDefault.PageIndex);
  const [filters] = React.useState<{ id: string; value: string }[]>([]);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const tableData = React.useMemo(() => {
    return viewsList.map((view) => {
      return {
        ...view,
        publicViewId: publicViewsData.find((publicView) => publicView.viewId === view.id)?.guid,
      };
    });
  }, [publicViewsData, viewsList]);

  React.useEffect(() => {
    // eslint-disable-next-line no-magic-numbers
    setPageIndex(pageNumber - 1);
  }, [pageNumber, pagination.PageNumber]);

  React.useEffect(() => {
    setPageIndex(paginationDefault.PageIndex);
    setPageNumber(paginationDefault.PageNumber);
  }, [pageSize, selectedDepartmentIds, selectedTemplateIds, viewNameFilter]);

  // ToDo: This can be lifted out of the component
  const hasViewAccess = React.useCallback(
    async (viewId: number) => {
      try {
        const response = await checkAccess(viewId).unwrap();

        if (response.hasAccess) {
          return true;
        }
      } catch (error) {
        toast({
          isClosable: true,
          position: 'top',
          status: ToastTypes.ERROR,
          title: 'Unable to check view access',
        });

        return false;
      }
    },
    [checkAccess, toast],
  );

  const handleAccess = React.useCallback(
    (view: ViewListItem) => {
      setSelectedViewId(view.id);
      toggleViewAccessDrawer();
    },
    [setSelectedViewId, toggleViewAccessDrawer],
  );

  const handleClone = React.useCallback(
    (view: ViewListItem) => {
      setSelectedViewId(view.id);
      toggleConfirmCloneModal();
    },
    [setSelectedViewId, toggleConfirmCloneModal],
  );

  const handleDelete = React.useCallback(
    (view: ViewListItem) => {
      setSelectedViewId(view.id);
      toggleConfirmDeleteModal();
    },
    [setSelectedViewId, toggleConfirmDeleteModal],
  );

  const handleEdit = React.useCallback(
    (view: ViewListItem) => {
      setSelectedViewId(view.id);
      toggleViewEditorDrawer();
    },
    [setSelectedViewId, toggleViewEditorDrawer],
  );

  const handleViewer = React.useCallback(
    async (view: ViewListItem) => {
      setSelectedViewId(view.id);
      const hasAccess = await hasViewAccess(view.id);

      if (hasAccess) {
        openViewInNewTab(view.id);
      } else {
        dispatch(setViewPageRequestViewAccessModalOpen(true));
      }
    },
    [dispatch, hasViewAccess, setSelectedViewId],
  );

  const getRowControls = React.useCallback(
    (view: ViewListItem) => {
      return (
        <ButtonGroup>
          <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleAccess(view)}>
            Access
          </Button>
          <Button
            data-testid={`${ELEMENT_DATA_TEST_IDS.CLONE_VIEW_BUTTON}-${view.id}`}
            colorScheme={'blue'}
            variant={'ghost'}
            onClick={() => handleClone(view)}
          >
            Clone
          </Button>
          <Button
            data-testid={`${ELEMENT_DATA_TEST_IDS.DELETE_VIEW_BUTTON}-${view.id}`}
            colorScheme={'blue'}
            variant={'ghost'}
            onClick={() => handleDelete(view)}
          >
            Delete
          </Button>
          <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleEdit(view)}>
            Edit
          </Button>
          <Button colorScheme={'blue'} variant={'ghost'} onClick={async () => handleViewer(view)}>
            Viewer
          </Button>
        </ButtonGroup>
      );
    },
    [handleAccess, handleClone, handleDelete, handleEdit, handleViewer],
  );

  const getViewPublicViewIdCell = (publicViewId: string): React.JSX.Element => {
    return publicViewId && lbBaseUrl ? (
      <Link href={`${lbBaseUrl}/public/${publicViewId}`} color={'blue.400'} isExternal>
        <Flex w={'150px'} h={'100%'} justify={'space-around'} style={{ textAlign: 'center' }} alignItems={'center'}>
          Visit Public View
          <FaExternalLinkAlt />
        </Flex>
      </Link>
    ) : (
      <Box>No Public View</Box>
    );
  };

  const columnHelper = createColumnHelper<ViewListItem>();

  const columns: ColumnDef<ViewListItem, string>[] = [
    columnHelper.accessor((view) => view.name, {
      cell: (info) => info.getValue(),
      header: () => <Box>View Name</Box>,
      id: 'name',
    }),
    columnHelper.accessor((view) => view.updatedTimestampCompact, {
      cell: (info) => info.getValue(),
      header: () => <Box>Last Modified</Box>,
      id: 'lastModified',
    }),
    columnHelper.accessor((view) => view.publicViewId, {
      cell: (info) => getViewPublicViewIdCell(info.row.original.publicViewId ?? ''),
      header: () => <Box>Public View</Box>,
      id: 'publicViewId',
    }),
    columnHelper.display({
      cell: (info) => getRowControls(info.row.original),
      id: 'rowControls',
    }),
  ];

  const handleGoToPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };
  const handleNextPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handlePreviousPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleSetPageSize = (pageSize: number) => {
    setPageNumber(paginationDefault.PageNumber);
    setPageSize(pageSize);
  };

  const getIsLoading = (): boolean => {
    return isLoading || isFetchingViewsList || isViewsListLoading;
  };

  if (getIsLoading()) {
    return <PaginatedTableSkeleton />;
  }

  // eslint-disable-next-line no-magic-numbers
  if (!tableData || tableData.length === 0) {
    return (
      <Box>
        <Text>No views found</Text>
      </Box>
    );
  }

  const getSortingState = (column: string) => {
    const currentSorting = sortingState.find((item) => item.id === column)?.desc;

    // If no sorting, set sorting to ascending (desc: false)
    if (currentSorting === undefined) {
      return false;
    }

    // If sorting is descending, set sorting to undefined (remove sorting)
    if (currentSorting) {
      return undefined;
    }

    // If sorting is ascending, set sorting to descending (desc: true)
    return true;
  };

  return (
    <div data-testid={ELEMENT_DATA_TEST_IDS.VIEWS_LIST}>
      <PaginatedTableControlled
        columns={columns}
        data={tableData as unknown as View[]}
        filters={filters}
        headerSortHandlers={[
          {
            columnId: 'name',
            sortHandler: () => setSortingStateById('name', getSortingState('name')),
          },
          {
            columnId: 'lastModified',
            sortHandler: () => setSortingStateById('lastModified', getSortingState('lastModified')),
          },
          {
            columnId: 'publicViewId',
            sortHandler: () => setSortingStateById('publicViewId', getSortingState('publicViewId')),
          },
        ]}
        isLoading={getIsLoading()}
        manualSorting={true}
        onGoToPage={handleGoToPage}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
        onSetPageSize={handleSetPageSize}
        overridePaginationModel={true}
        pagination={{ ...pagination, PageIndex: pageIndex }}
        sortingState={sortingState}
      />
    </div>
  );
};

export default ViewsListView;
