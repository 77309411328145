import { Box, FormControl, FormLabel, HStack, Switch, VStack } from '@chakra-ui/react';
import { ColumnFiltersState, createColumnHelper, Row } from '@tanstack/react-table';
import { produce, WritableDraft } from 'immer';
import React from 'react';
import Select from 'react-select';
import { useImmerReducer } from 'use-immer';

import { useGetPersonnelBySearchQuery } from '@/API/personnel.api';
import AvailablePreviewList from '@/components/available-preview-list/AvailablePreviewList';
import FilterSelect, { FilterSelectValue } from '@/components/filter-select/FilterSelect';
import {
  DEFAULT_ARRAY_ELEMENT_DELETE_COUNT,
  DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND,
  DEFAULT_COLOR_ITEM,
  DEFAULT_PERSONNEL_SORT_ORDER,
  DEFAULT_Z_INDEX,
} from '@/constants/defaults';
import { FilterSelectType } from '@/constants/enums';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setViewEditorDraftAutoAddPersonnel,
  setViewEditorDraftPersonnel,
  setViewEditorDraftSortByPersonnel,
} from '@/store/slices/viewEditor.slice';
import { PersonnelItemColored } from '@/types/personnel.types';
import { PersonnelSortOrderDropDownOption, PersonnelSortOrderDropDownOptions } from '@/types/ui.types';
import {
  GridSettingsLeftColumnType,
  ViewEditorDraftSortByPersonnelOptions,
  ViewLayoutDataType,
} from '@/types/view.types';
import SortingUtils from '@/utils/sorting';
import { isGroupByAssignmentsLayout } from '@/utils/validators';

// Key to sort items on the table
const PERSONNEL_SORT_KEY = 'id';

type PersonnelToggleFiltersProps = {
  hideInactivePersonnel?: boolean;
};

enum ViewEditorPersonnelColumns {
  displayName = 'displayName',
  expired = 'expired',
  personnelId = 'personnelId',
  personnelTypeId = 'personnelTypeId',
}

interface UIState {
  isDraggable: boolean;
  personnelToggleFilters: PersonnelToggleFiltersProps;
  selectedPersonnelTypes: number[];
}

// ToDo: Expand UIState into this
interface TableState {
  availableItemsColumnFilters: ColumnFiltersState;
  isHydrated: boolean;
  isLoading: boolean;
  pendingChanges: PersonnelItemColored[];
  uiState: UIState;
}

enum ReducerActionTypes {
  SELECT_PERSONNEL_TYPES,
  SET_AVAILABLE_ITEMS_COLUMN_FILTERS,
  SET_IS_DRAGGABLE,
  SET_IS_HYDRATED,
  SET_IS_LOADING,
  TOGGLE_HIDE_INACTIVE_PERSONNEL,
}

type ReducerAction = { type: ReducerActionTypes; payload: unknown };

const displayName = (rowA: Row<PersonnelItemColored>, rowB: Row<PersonnelItemColored>) => {
  return SortingUtils.sortStrings(rowA.original.personnelName.display, rowB.original.personnelName.display);
};

const lastName = (rowA: Row<PersonnelItemColored>, rowB: Row<PersonnelItemColored>) => {
  return SortingUtils.sortStrings(rowA.original.personnelName.last, rowB.original.personnelName.last);
};

const stateReducer = (draft: TableState, action: ReducerAction) => {
  switch (action.type) {
    case ReducerActionTypes.SET_IS_HYDRATED: {
      draft.isHydrated = action.payload as boolean;
      break;
    }
    case ReducerActionTypes.SELECT_PERSONNEL_TYPES: {
      draft.uiState.selectedPersonnelTypes = action.payload as number[];
      break;
    }
    case ReducerActionTypes.SET_AVAILABLE_ITEMS_COLUMN_FILTERS: {
      draft.availableItemsColumnFilters = action.payload as ColumnFiltersState;
      break;
    }
    case ReducerActionTypes.SET_IS_LOADING: {
      draft.isLoading = action.payload as boolean;
      break;
    }
    // case ReducerActionTypes.SET_PERSONNEL_SORT_ORDER: {
    //   const payloadValue = (action.payload as PersonnelSortOrderDropDownOption).value;
    //   const isDraggable =
    //     payloadValue === ViewEditorDraftSortByPersonnelOptions.TIME_THEN_CUSTOM ||
    //     payloadValue === ViewEditorDraftSortByPersonnelOptions.CUSTOM;
    //
    //   // draft.uiState.personnelSortValue = action.payload as PersonnelSortOrderDropDownOption;
    //   draft.uiState.isDraggable = isDraggable;
    //
    //   // if (isDraggable) {
    //   //   // eslint-disable-next-line no-magic-numbers
    //   //   draft.previewItems = new Map([...draft.previewItems.entries()].sort((a, b) => SortingUtils.sortStrings(a[1].personnelName.display, b[1].personnelName.display)));
    //   // }
    //
    //   break;
    // }
    case ReducerActionTypes.SET_IS_DRAGGABLE: {
      draft.uiState.isDraggable = action.payload as boolean;
      break;
    }
    case ReducerActionTypes.TOGGLE_HIDE_INACTIVE_PERSONNEL: {
      draft.uiState.personnelToggleFilters.hideInactivePersonnel =
        !draft.uiState.personnelToggleFilters.hideInactivePersonnel;
      break;
    }
  }
};

const ViewEditorPersonnel = (): React.JSX.Element => {
  const {
    uiState: { personnelTypes },
    viewDraft: view,
  } = useAppSelector((state) => state.viewEditor);

  const { filter } = view;

  const dispatch = useAppDispatch();

  const [state, localDispatch] = useImmerReducer(stateReducer, {
    availableItemsColumnFilters: [],
    isHydrated: false,
    isLoading: true,
    pendingChanges: [],
    uiState: {
      isDraggable:
        filter.sort_personnel_by === ViewEditorDraftSortByPersonnelOptions.TIME_THEN_CUSTOM ||
        filter.sort_personnel_by === ViewEditorDraftSortByPersonnelOptions.CUSTOM,
      personnelToggleFilters: {
        hideInactivePersonnel: filter?.hide_inactive_personnel,
      },
      selectedPersonnelTypes: [],
    },
  });

  const filterPersonnelRef = React.useRef(filter.on_personnel);
  const selectedPersonnelIdsRef = React.useRef<number[]>(
    filter.on_personnel.map((personnel) => personnel.id as number),
  );

  const currentPersonnelIds = React.useMemo(() => {
    if (
      JSON.stringify(selectedPersonnelIdsRef.current) ===
      JSON.stringify(filter.on_personnel.map((personnel) => personnel.id))
    )
      return selectedPersonnelIdsRef.current;

    selectedPersonnelIdsRef.current = filter.on_personnel.map((personnel) => personnel.id as number);
    filterPersonnelRef.current = filter.on_personnel;

    return selectedPersonnelIdsRef.current;
  }, [filter.on_personnel]);

  const { data: personnelData, isLoading: isPersonnelDataLoading } = useGetPersonnelBySearchQuery(
    {
      excludeNonScheduled: true,
      searchBody: {
        DepartmentIds: filter.on_departments,
        PersonnelIds: currentPersonnelIds,
        Slim: true,
      },
    },
    { skip: !filter.on_departments.length || state.isHydrated },
  );

  // If the view departments changed, we need to re-fetch the personnel data
  React.useEffect(() => {
    localDispatch({ payload: false, type: ReducerActionTypes.SET_IS_HYDRATED });
  }, [filter.on_departments, localDispatch]);

  // Once the personnel data are loaded, we don't want to re-fetch them
  React.useEffect(() => {
    if (personnelData) localDispatch({ payload: true, type: ReducerActionTypes.SET_IS_HYDRATED });
  }, [localDispatch, personnelData]);

  // Show personnel sort order options based on the view layout, data type, and group by settings
  const personnelSortOrderOptions = React.useMemo(() => {
    const layoutType = view.theme.data.layout;
    const dataType = view.theme.data.dataType;
    const groupBy = view.theme.data.GridSettings_leftColumnType;

    if (
      isGroupByAssignmentsLayout(layoutType) &&
      dataType === ViewLayoutDataType.REQUEST &&
      groupBy === GridSettingsLeftColumnType.ASSIGNMENT
    ) {
      return PersonnelSortOrderDropDownOptions;
    }

    return PersonnelSortOrderDropDownOptions.filter(
      (option) => option.value !== ViewEditorDraftSortByPersonnelOptions.REQUEST_TIME_SUBMITTED,
    );
  }, [view.theme.data.layout, view.theme.data.dataType, view.theme.data.GridSettings_leftColumnType]);

  // When the selected personnel items change, we need to update the table data
  const { availableItems, previewItems } = React.useMemo(() => {
    const previewItems = new Map<number, PersonnelItemColored>();
    const availableItems = new Map<number, PersonnelItemColored>();

    if (isPersonnelDataLoading || !personnelData) {
      return {
        availableItems,
        previewItems,
      };
    }

    // Hydrate the available items
    for (const personnel of personnelData ?? []) {
      if (currentPersonnelIds.every((id) => id !== personnel.id)) {
        availableItems.set(personnel.id, { ...personnel, ...DEFAULT_COLOR_ITEM, id: personnel.id });
      } else {
        availableItems.delete(personnel.id);
      }
    }

    // Hydrate the preview items
    filter.on_personnel.forEach((personnelColorData) => {
      const personnel = personnelData.find((personnel) => personnel.id === personnelColorData.id);

      if (!personnel) return;

      previewItems.set(personnel.id, { ...personnel, ...personnelColorData, id: personnel.id });
    });

    return { availableItems, previewItems };
  }, [currentPersonnelIds, isPersonnelDataLoading, personnelData, filter.on_personnel]);

  const updateColumnFilter = (id: ViewEditorPersonnelColumns, value: unknown, draft: WritableDraft<TableState>) => {
    const filterIdx = (state.availableItemsColumnFilters as ColumnFiltersState).findIndex((filter) => filter.id === id);

    // Hide Inactive Personnel radio button uses "expired" property to filter the data
    // When filtering the expired property we need to reverse the logic to hide the inactive(expired) personnel
    if (id === ViewEditorPersonnelColumns.expired) {
      // In order to hide the inactive(expired) personnel and show only the active ones, the value of the filter should be "false"
      // Otherwise we remove the value and display all the items
      if (value) {
        value = false;
      } else value = undefined;
    }

    if (filterIdx === DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND) {
      draft.availableItemsColumnFilters.push({
        id,
        value,
      });
    } else {
      if (!value) {
        draft.availableItemsColumnFilters.splice(filterIdx, DEFAULT_ARRAY_ELEMENT_DELETE_COUNT);
        return;
      }
      draft.availableItemsColumnFilters[filterIdx] = {
        id,
        value,
      };
    }
  };

  const personnelTypeFilterFn = (row: Row<PersonnelItemColored>, _id: string, filterValue: number[]) => {
    if (!filterValue.length) return true;

    const personnelTypeId = row.getValue(ViewEditorPersonnelColumns.personnelTypeId);

    return filterValue.includes(personnelTypeId as number);
  };

  personnelTypeFilterFn.autoRemove = (val: string) => !val;

  const columnHelper = createColumnHelper<PersonnelItemColored>();

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.personnelName.display, {
      cell: undefined,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorPersonnelColumns.displayName,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.pTypeId, {
      cell: undefined,
      enableHiding: true,
      filterFn: personnelTypeFilterFn,
      id: ViewEditorPersonnelColumns.personnelTypeId,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorPersonnelColumns.expired,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorPersonnelColumns.personnelId,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<PersonnelItemColored>();

  const previewColumnFilterFn = React.useMemo(() => {
    const value = filter.sort_personnel_by;

    switch (value) {
      case ViewEditorDraftSortByPersonnelOptions.LAST_NAME:
        return lastName;
      case ViewEditorDraftSortByPersonnelOptions.DISPLAY_NAME:
        return displayName;
      default:
        return displayName;
    }
  }, [filter.sort_personnel_by]);

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.personnelName.display, {
      cell: undefined,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorPersonnelColumns.displayName,
      sortingFn: previewColumnFilterFn,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.pTypeId, {
      cell: undefined,
      enableHiding: true,
      // filterFn: personnelTypeFilterFn,
      id: ViewEditorPersonnelColumns.personnelTypeId,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorPersonnelColumns.expired,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorPersonnelColumns.personnelId,
    }),
  ];

  const handleInactivePersonnelToggle = () => {
    localDispatch({
      payload: null,
      type: ReducerActionTypes.TOGGLE_HIDE_INACTIVE_PERSONNEL,
    });

    const columnFiltersDraft = produce(state, (draft) => {
      updateColumnFilter(
        ViewEditorPersonnelColumns.expired,
        !state.uiState.personnelToggleFilters.hideInactivePersonnel,
        draft,
      );
    });

    localDispatch({
      payload: [...columnFiltersDraft.availableItemsColumnFilters],
      type: ReducerActionTypes.SET_AVAILABLE_ITEMS_COLUMN_FILTERS,
    });
  };

  // ToDo: Extract this to a separate component
  const getTopBar = (): React.JSX.Element => {
    return (
      <HStack zIndex={DEFAULT_Z_INDEX} spacing="24px" alignItems="flex-start">
        <VStack alignItems="flex-start">
          <FormLabel>Filter Available Personnel by Personnel Types</FormLabel>
          <FilterSelect
            noGap
            hasSelectAll={true}
            inputName={FilterSelectType.FILTER_BY_PERSONNEL_TYPE}
            isMultiSelect={true}
            onChangeHandler={(values) => {
              const selectedPersonnelTypes = (values as FilterSelectValue[]).map(
                (personnelType) => personnelType.value,
              );

              const columnFiltersDraft = produce(state, (draft) => {
                updateColumnFilter(ViewEditorPersonnelColumns.personnelTypeId, selectedPersonnelTypes, draft);
              });

              localDispatch({
                payload: selectedPersonnelTypes,
                type: ReducerActionTypes.SELECT_PERSONNEL_TYPES,
              });

              localDispatch({
                payload: [...columnFiltersDraft.availableItemsColumnFilters],
                type: ReducerActionTypes.SET_AVAILABLE_ITEMS_COLUMN_FILTERS,
              });
            }}
            options={personnelTypes}
            optionValueKey={'ptypeId'}
            optionLabelKey={'name'}
            placeHolderText={'Personnel Types'}
            selectedValues={state.uiState.selectedPersonnelTypes}
          />
        </VStack>

        <VStack alignItems="flex-start">
          <FormLabel>Sort Selected Personnel by</FormLabel>
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                maxWidth: '250px',
                minWidth: '250px',
              }),
            }}
            options={personnelSortOrderOptions}
            value={
              personnelSortOrderOptions.find(({ value }) => value === filter.sort_personnel_by) ??
              DEFAULT_PERSONNEL_SORT_ORDER
            }
            onChange={(option) => {
              const optionTyped = option as PersonnelSortOrderDropDownOption;
              const prevDraggable = state.uiState.isDraggable;
              const isDraggable =
                optionTyped.value === ViewEditorDraftSortByPersonnelOptions.TIME_THEN_CUSTOM ||
                optionTyped.value === ViewEditorDraftSortByPersonnelOptions.CUSTOM;

              if (prevDraggable !== isDraggable) {
                localDispatch({
                  payload: isDraggable,
                  type: ReducerActionTypes.SET_IS_DRAGGABLE,
                });
              }

              dispatch(setViewEditorDraftSortByPersonnel(optionTyped.value));
            }}
          />
        </VStack>

        <VStack alignItems="flex-end" height="100%" justifyContent="flex-end">
          <FormControl display="flex" alignItems="center">
            <HStack w={'240px'} justifyContent={'space-between'}>
              <FormLabel htmlFor="auto-add-personnel" mb="0">
                Auto-Add Personnel
              </FormLabel>
              <Switch
                id="auto-add-personnel"
                isChecked={filter.auto_add_personnel}
                onChange={(event) => {
                  dispatch(setViewEditorDraftAutoAddPersonnel(event.target.checked));
                }}
              />
            </HStack>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <HStack w={'240px'} justifyContent={'space-between'}>
              <FormLabel htmlFor="hide-inactive-from-available" mb="0">
                Hide Inactive Personnel
              </FormLabel>
              <Switch
                id="hide-inactive-from-available"
                isChecked={state.uiState.personnelToggleFilters.hideInactivePersonnel}
                onChange={handleInactivePersonnelToggle}
              />
            </HStack>
          </FormControl>
        </VStack>
      </HStack>
    );
  };

  const handleItemsChanged = (items: PersonnelItemColored[]) => {
    dispatch(setViewEditorDraftPersonnel(items));
  };

  // ToDo: Handle no personnel found
  return (
    <VStack gap={5} align={'top'}>
      {getTopBar()}
      <AvailablePreviewList
        availableColumns={availableItemsColumns}
        availableColumnsVisibility={{
          [ViewEditorPersonnelColumns.personnelTypeId]: false,
          [ViewEditorPersonnelColumns.expired]: false,
          [ViewEditorPersonnelColumns.personnelId]: false,
        }}
        availableItems={availableItems}
        availableItemsColumnFilters={state.availableItemsColumnFilters}
        availableItemsPrimaryColumnId={ViewEditorPersonnelColumns.displayName}
        availableItemsTableLabel={'Available Personnel'}
        availableRowUniqueKey={PERSONNEL_SORT_KEY}
        isOpen={true}
        isLoading={isPersonnelDataLoading}
        onItemsChanged={(items: PersonnelItemColored[]) => handleItemsChanged(items)}
        overrideSorting={state.uiState.isDraggable}
        previewColumns={previewColumns}
        previewColumnsVisibility={{
          [ViewEditorPersonnelColumns.personnelTypeId]: false,
          [ViewEditorPersonnelColumns.expired]: false,
          [ViewEditorPersonnelColumns.personnelId]: false,
        }}
        previewItems={previewItems}
        previewItemsPrimaryColumnId={ViewEditorPersonnelColumns.displayName}
        previewItemsTableLabel={'Selected Personnel'}
        previewRowsAreDraggable={state.uiState.isDraggable}
        previewRowUniqueKey={PERSONNEL_SORT_KEY}
        primaryAvailableColumnIndex={0}
        primaryPreviewColumnIndex={0}
        showColorPicker={true}
        showSideControls={true}
        tableColumnIds={ViewEditorPersonnelColumns}
      />
    </VStack>
  );
};

export default ViewEditorPersonnel;
