export interface Item {
  [key: string]: string | number;
}

export const arraysAreEqual = (a: Array<number | string>, b: Array<number | string>) => {
  return a.length === b.length && a.every((val, index) => val === b[index]);
};

export const setsAreEqual = (xs: Set<number | string>, ys: Set<number | string>) =>
  xs.size === ys.size && [...xs].every((x) => ys.has(x));

// Sort array based on a reference array
export const customSortArray = <T>(arrayToSort: T[], referenceArray: Array<number | string>, key: string): T[] => {
  return [...arrayToSort].sort(
    (a, b) =>
      referenceArray.indexOf(a[key as keyof T] as number | string) -
      referenceArray.indexOf(b[key as keyof T] as number | string),
  );
};

type FilterFunction = (item: number | string) => boolean;

export const arraysContainSameValues = (
  a: Array<number | string>,
  b: Array<number | string>,
  filters?: FilterFunction[],
) => {
  if (filters) {
    a = a.filter((item) => !filters.every((filter) => filter(item)));
    b = b.filter((item) => !filters.every((filter) => filter(item)));
  }

  return a.length === b.length && a.every((val) => b.includes(val));
};

type UniqueObject<T> = { [key: string]: T };

const compareUniqueObjects = <T>(a: UniqueObject<T>, b: UniqueObject<T>, omitKeys?: string[]) => {
  for (const key in a) {
    if (omitKeys?.includes(key)) {
      continue;
    }

    const tempA = a[key];
    const tempB = b[key];

    if (typeof tempA === 'object' && typeof tempB === 'object') {
      omitKeys?.forEach((omitKey) => {
        if (tempA?.[omitKey as keyof T]) {
          delete tempA[omitKey as keyof T];
        }

        if (tempB?.[omitKey as keyof T]) {
          delete tempB[omitKey as keyof T];
        }
      });

      if (!compareUniqueObjects(tempA as UniqueObject<T>, tempB as UniqueObject<T>, omitKeys)) {
        return false;
      }
    } else {
      if (JSON.stringify(tempA) !== JSON.stringify(tempB)) {
        return false;
      }
    }
  }

  return true;
};

export function arraysContainSameUniqueObjects<T>(
  a: UniqueObject<T>[],
  b: UniqueObject<T>[],
  uniqueKey: string,
  omitKeys?: string[],
  logMissingKeys?: boolean,
  collectionADebugName?: string,
  collectionBDebugName?: string,
  skipCompare?: boolean,
): boolean;

export function arraysContainSameUniqueObjects<T>(
  a: UniqueObject<T>[],
  b: UniqueObject<T>[],
  uniqueKey?: string,
  omitKeys?: string[],
  logMissingKeys?: boolean,
  collectionADebugName?: string,
  collectionBDebugName?: string,
  skipCompare?: boolean,
  uniqueKeyA?: string,
  uniqueKeyB?: string,
): boolean;

export function arraysContainSameUniqueObjects<T>(
  a: UniqueObject<T>[],
  b: UniqueObject<T>[],
  uniqueKey?: string,
  omitKeys?: string[],
  logMissingKeys?: boolean,
  collectionADebugName?: string,
  collectionBDebugName?: string,
  skipCompare?: boolean,
  uniqueKeyA?: string,
  uniqueKeyB?: string,
) {
  if (a.length !== b.length) {
    if (!logMissingKeys) return false;
  }

  if (!uniqueKey) {
    if (!uniqueKeyA && !uniqueKeyB) {
      throw new Error('Either uniqueKey, uniqueKeyA, or uniqueKeyB must be provided');
    }
  }

  const keyA = uniqueKeyA ?? uniqueKey;
  const keyB = uniqueKeyB ?? uniqueKey;

  if (keyA && keyB) {
    for (const itemA of a) {
      const itemB = b.find((item) => item[keyB] === itemA[keyA]);

      if (!itemB) {
        if (logMissingKeys) {
          console.log(
            `Searching for unique key: ${itemA[keyA]} from ${collectionADebugName} in ${collectionBDebugName}.`,
          );
          console.log(`${collectionBDebugName} does not contain item: ${itemA[keyB]}.`);
        }

        return false;
      }

      if (skipCompare) {
        continue;
      }

      if (!compareUniqueObjects(itemA, itemB, omitKeys)) {
        return false;
      }
    }
  }

  return true;
}
