import { FormLabel, HStack, Switch, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

import CustomDropdown from '@/components/custom-dropdown/CustomDropdown';
import WithInfoToolTip from '@/components/info-tooltip/WithInfoToolTip';
import { DEFAULT_TABLE_SIZE } from '@/constants/defaults';
import UIConstants from '@/constants/ui';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setViewEditorDraftThemeData } from '@/store/slices/viewEditor.slice';
import { YesNoAsBoolean } from '@/types/ui.types';
import {
  ViewEditorDisplayDepartmentNames,
  ViewLayoutType,
  ViewThemeData,
  ViewThemeStartOnDay,
  ViewThemeStartOnDayMap,
} from '@/types/view.types';

const VERTICAL_OFFSET_FOR_DROPDOWN = 30;
const DROPDOWN_WIDTH = '300px';

const ViewEditorViewerConfiguration = (): React.JSX.Element => {
  const { viewDraft: view } = useAppSelector((state) => state.viewEditor);

  const dispatch = useAppDispatch();

  const { theme } = view;

  const [themeData, setThemeData] = React.useState(theme.data);

  const setStartDayOfWeek = (day: ViewThemeStartOnDay) => {
    setThemeData((prevState) => {
      return { ...prevState, startOnDay: day };
    });
  };

  const setDepartmentNamesDisplay = (option: ViewEditorDisplayDepartmentNames) => {
    setThemeData((prevState) => {
      return { ...prevState, displayDepartmentNames: option };
    });
  };

  type ToggleChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, themeDataKey: keyof ViewThemeData) => void;
  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>, themeDataKey: keyof ViewThemeData) => {
    const { checked } = e.target;

    setThemeData((prevState) => {
      return { ...prevState, [themeDataKey]: checked ? 'yes' : 'no' };
    });
  };

  const getToggleRow = (
    label: string,
    value: boolean,
    onChange: ToggleChangeHandler,
    themeDataKey: keyof ViewThemeData,
    tooltipText: string,
  ) => {
    return (
      <HStack justifyContent={'space-between'} align={'center'} key={label}>
        <FormLabel>{label}</FormLabel>
        <WithInfoToolTip text={tooltipText}>
          <Switch isChecked={value} onChange={(e) => onChange(e, themeDataKey)} />
        </WithInfoToolTip>
      </HStack>
    );
  };

  const buildOptions = () => {
    const options = [];
    const { layout } = themeData;

    if (layout === ViewLayoutType.STANDARD || layout === ViewLayoutType.CALENDAR) {
      options.push(
        <CustomDropdown
          key={'startOnDay'}
          label={'Start Day of Week'}
          options={Object.values(ViewThemeStartOnDayMap)}
          onChange={(e) =>
            setStartDayOfWeek(
              Object.entries(ViewThemeStartOnDayMap).find(
                ([, value]) => value === e.target.value,
                // eslint-disable-next-line no-magic-numbers
              )?.[0] as ViewThemeStartOnDay,
            )
          }
          selectedOption={
            themeData.startOnDay ? ViewThemeStartOnDayMap[themeData.startOnDay] : ViewThemeStartOnDayMap['Mon']
          }
          width={DROPDOWN_WIDTH}
        />,
      );
    }

    if (layout === ViewLayoutType.STANDARD || layout === ViewLayoutType.COLUMNS || layout === ViewLayoutType.CALENDAR) {
      options.push(
        <WithInfoToolTip
          text={UIConstants.TOOLTIPS.DisplayDepartmentNames}
          iconStyle={{ position: 'relative', top: VERTICAL_OFFSET_FOR_DROPDOWN }}
        >
          <CustomDropdown
            key={'displayDepartmentNames'}
            label={'Show Department Names'}
            options={Object.values(ViewEditorDisplayDepartmentNames).map((option) =>
              option
                .split(' ')
                .map((word) => _.upperFirst(word))
                .join(' '),
            )}
            onChange={(e) => {
              const normalizedValue = e.target.value
                .split(' ')
                .map((word) => _.upperFirst(word))
                .join(' ');
              setDepartmentNamesDisplay(normalizedValue as ViewEditorDisplayDepartmentNames);
            }}
            selectedOption={(themeData.displayDepartmentNames
              ? themeData.displayDepartmentNames
              : ViewEditorDisplayDepartmentNames.NONE
            )
              .split(' ')
              .map((word) => _.upperFirst(word))
              .join(' ')}
            width={DROPDOWN_WIDTH}
          />
        </WithInfoToolTip>,
      );
    }

    options.push(
      getToggleRow(
        'Compact Personnel and Assignment Names',
        themeData.compactMode ? YesNoAsBoolean[themeData.compactMode] : false,
        handleToggleChange,
        'compactMode',
        UIConstants.TOOLTIPS.CompactMode,
      ),
    );

    if (layout === ViewLayoutType.COLUMNS) {
      options.push(
        getToggleRow(
          'Compact Columns to Fit Screen',
          themeData.condenseColumns ? YesNoAsBoolean[themeData.condenseColumns] : false,
          handleToggleChange,
          'condenseColumns',
          UIConstants.TOOLTIPS.CondenseColumnToFitScreen,
        ),
      );
    }

    if (layout === ViewLayoutType.STANDARD || layout === ViewLayoutType.COLUMNS || layout === ViewLayoutType.BLOCK) {
      options.push(
        getToggleRow(
          'Hide Blank Rows',
          themeData.hideBlankRows ? YesNoAsBoolean[themeData.hideBlankRows] : false,
          handleToggleChange,
          'hideBlankRows',
          UIConstants.TOOLTIPS.HideBlankRows,
        ),
      );
    }

    if (layout === ViewLayoutType.STANDARD || layout === ViewLayoutType.COLUMNS || layout === ViewLayoutType.CALENDAR) {
      options.push(
        getToggleRow(
          'Hide Weekends',
          themeData.hideWeekends ? YesNoAsBoolean[themeData.hideWeekends] : false,
          handleToggleChange,
          'hideWeekends',
          UIConstants.TOOLTIPS.HideWeekends,
        ),
      );

      options.push(
        getToggleRow(
          'Show Times',
          themeData.showTimes ? YesNoAsBoolean[themeData.showTimes] : false,
          handleToggleChange,
          'showTimes',
          UIConstants.TOOLTIPS.ShowTimes,
        ),
      );

      options.push(
        getToggleRow(
          'Enable Saved Filters',
          themeData.personalFilter ? YesNoAsBoolean[themeData.personalFilter] : false,
          handleToggleChange,
          'personalFilter',
          UIConstants.TOOLTIPS.EnableSaveFilters,
        ),
      );
    }

    if (layout === ViewLayoutType.STANDARD || layout === ViewLayoutType.COLUMNS) {
      options.push(
        getToggleRow(
          'Tallies Count Personnel Based on Filter',
          themeData.shouldCountTalliesByPersonnelFilter
            ? YesNoAsBoolean[themeData.shouldCountTalliesByPersonnelFilter]
            : false,
          handleToggleChange,
          'shouldCountTalliesByPersonnelFilter',
          UIConstants.TOOLTIPS.TalliesCountPersonnelBasedOnFilter,
        ),
      );
    }

    if (layout === ViewLayoutType.LIST) {
      options.push(
        getToggleRow(
          'Hide Pending Slots',
          themeData.hidePending ? YesNoAsBoolean[themeData.hidePending] : false,
          handleToggleChange,
          'hidePending',
          UIConstants.TOOLTIPS.HidePendingSlots,
        ),
      );
    }

    options.push(
      getToggleRow(
        'Show Logged In User Only',
        themeData.showLoggedInOnly ? YesNoAsBoolean[themeData.showLoggedInOnly] : false,
        handleToggleChange,
        'showLoggedInOnly',
        UIConstants.TOOLTIPS.ShowLoggedInUserOnly,
      ),
    );

    return options;
  };

  React.useEffect(() => {
    dispatch(setViewEditorDraftThemeData(themeData));
  }, [dispatch, themeData]);

  return (
    <VStack align={'left'} justifyContent={'space-between'} gap={6} w={`${DEFAULT_TABLE_SIZE}px`}>
      {buildOptions()}
    </VStack>
  );
};

export default ViewEditorViewerConfiguration;
