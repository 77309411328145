import { Alert, AlertIcon, Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FaEye } from 'react-icons/fa';

import AvailablePreviewList from '@/components/available-preview-list/AvailablePreviewList';
import ViewLayoutDisplayRange from '@/components/view-editor/ViewLayoutDisplayRange';
import ViewLayoutGroupBy from '@/components/view-editor/ViewLayoutGroupBy';
import { LAYOUT_LIST_PUBLIC_ONLY_COLUMNS } from '@/constants/config';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setViewEditorDraftThemeDataLayoutListColumns } from '@/store/slices/viewEditor.slice';
import { UniqueId } from '@/types/ui.types';
import { ViewLayoutListColumns } from '@/types/view.types';

interface ViewLayoutListProps {
  displayRange: string;
  groupBy: string;
  handleDisplayRangeChange: (value: string) => void;
  handleGroupByChange: (value: string) => void;
}

interface ListColumnData {
  id: UniqueId;
  name: string;
}

enum ViewEditorListViewColumns {
  name = 'name',
}

const allColumns = Object.values(ViewLayoutListColumns);
const columnIdMap = new Map<string, ListColumnData>();

allColumns.forEach((column) => columnIdMap.set(column, { id: column, name: column }));

const ViewLayoutList = (props: ViewLayoutListProps): React.JSX.Element => {
  const { displayRange, groupBy, handleDisplayRangeChange, handleGroupByChange } = props;
  const selectedRange = ['day', 'week'].includes(displayRange) ? displayRange : 'day';

  const { viewDraft: view } = useAppSelector((state) => state.viewEditor);

  const dispatch = useAppDispatch();

  const currentSelectedColumns = React.useMemo(() => view.theme.data.listColumns ?? [], [view.theme.data.listColumns]);

  const [availableItems, previewItems] = React.useMemo(() => {
    const availableItemsMap = new Map<string, ListColumnData>();
    const previewItemsMap = new Map<string, ListColumnData>();

    // Hydrate available items, order does not matter
    for (const [k, v] of columnIdMap.entries()) {
      if (!currentSelectedColumns?.includes(v.name as ViewLayoutListColumns)) {
        availableItemsMap.set(k, v);
      }
    }

    // Hydrate preview items, order DOES matter
    currentSelectedColumns.forEach((columnName) => {
      if (columnIdMap.has(columnName)) {
        previewItemsMap.set(columnName, columnIdMap.get(columnName) as ListColumnData);
      }
    });

    return [availableItemsMap, previewItemsMap];
  }, [currentSelectedColumns]);

  const availableColumnsHelper = createColumnHelper<ListColumnData>();

  const injectCellContent = (item: CellContext<ListColumnData, string>) => {
    if (LAYOUT_LIST_PUBLIC_ONLY_COLUMNS.includes(item.row.original.name as ViewLayoutListColumns)) {
      return <Icon as={FaEye} margin={'0 4px'} fontSize={'xl'} verticalAlign={'center'} />;
    }

    return <></>;
  };

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    availableColumnsHelper.accessor((row) => row.name, {
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorListViewColumns.name,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<ListColumnData>();

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.name, {
      enableSorting: false,
      header: () => <Box>Name</Box>,
      id: ViewEditorListViewColumns.name,
    }),
  ];

  const handleUpdateSelectedItems = React.useCallback(
    (items: ListColumnData[]) => {
      dispatch(setViewEditorDraftThemeDataLayoutListColumns(items.map((item) => item.name)));
    },
    [dispatch],
  );

  return (
    <VStack align={'left'} gap={5}>
      <HStack gap={10}>
        <ViewLayoutDisplayRange
          options={[
            { label: 'Day', value: 'day' },
            { label: 'Week', value: 'week' },
          ]}
          selected={selectedRange}
          onChange={handleDisplayRangeChange}
        />
        <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
      </HStack>

      <Alert status="info">
        <AlertIcon />
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <p>For publicly accessible list views, only those columns marked with</p>
          <Icon as={FaEye} margin={'0 4px'} fontSize={'xl'} verticalAlign={'center'} />
          <p>will be visible.</p>
        </HStack>
      </Alert>

      <VStack gap={5} align={'top'}>
        <AvailablePreviewList
          availableColumns={availableItemsColumns}
          availableItems={availableItems}
          availableItemsPrimaryColumnId={'name'}
          availableItemsTableLabel={'Available Columns'}
          availableRowUniqueKey={'id'}
          cellContentInjector={injectCellContent}
          isOpen={true}
          onItemsChanged={handleUpdateSelectedItems}
          overrideSorting={true}
          previewColumns={previewColumns}
          previewItems={previewItems}
          previewItemsPrimaryColumnId={'name'}
          previewItemsTableLabel={'Selected Columns'}
          previewRowUniqueKey={'id'}
          previewRowsAreDraggable={true}
          primaryAvailableColumnIndex={0}
          primaryPreviewColumnIndex={0}
          showSideControls={true}
          tableColumnIds={ViewEditorListViewColumns}
        />
      </VStack>
    </VStack>
  );
};

export default ViewLayoutList;
