import { Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

import { BODY_SKELETON_WIDTH, DEFAULT_SKELETON_NUMBER_OF_ROWS, FULL_WIDTH } from '@/constants/ui';

interface PreviewListSkeletonProps {
  rows?: number;
}

const PreviewListSkeleton = ({ rows }: PreviewListSkeletonProps): React.JSX.Element => {
  const numberOfRows = [...Array(rows ?? DEFAULT_SKELETON_NUMBER_OF_ROWS).keys()];
  return (
    <Stack spacing={2} width={FULL_WIDTH}>
      {numberOfRows.map((item) => (
        <Skeleton key={item} height={BODY_SKELETON_WIDTH} />
      ))}
    </Stack>
  );
};

export default PreviewListSkeleton;
