/* eslint-disable camelcase*/
import { ConfigHelper, ConfigKeys } from '@/utils/env';

enum API_ERRORS {
  BAD_REQUEST = 'bad_request',
  INVALID_CLIENT = 'invalid_client',
  INVALID_GRANT = 'invalid_grant',
  NOT_FOUND = 'not_found',
}

const AUTHORIZATION_PAGE_MESSAGE = 'This page requires authentication.';

const BASE_URL = ConfigHelper.getConfig(ConfigKeys.LB_API_HOST);

const CLIENT_ID = ConfigHelper.getConfig(ConfigKeys.CLIENT_ID);

export enum ELEMENT_DATA_TEST_IDS {
  CANCEL_ACTION_BUTTON = 'cancel-action-button',
  CLONE_VIEW_BUTTON = 'clone-view',
  CLONE_VIEW_DIALOG = 'clone-view-dialog',
  CLONE_VIEW_DIALOG_CANCEL_BUTTON = 'clone-view-dialog-cancel-button',
  CLONE_VIEW_DIALOG_CLONE_AND_EDIT_BUTTON = 'clone-view-dialog-clone-and-edit-button',
  CLONE_VIEW_DIALOG_CLONE_BUTTON = 'clone-view-dialog-clone-button',
  CLONE_VIEW_DIALOG_INPUT = 'clone-view-dialog-input',
  COLOR_PICKER_BUTTON = 'color-picker-button',
  CONFIRM_ACTION_BUTTON = 'confirm-action-button',
  CONFIRM_ACTION_MODAL = 'confirm-action-modal',
  CONFIRM_DELETE_VIEW_NAME = 'confirm-delete-view-name',
  DELETE_VIEW_BUTTON = 'delete-view-button',
  FIRST_PAGE_BUTTON = 'first-page-button',
  LAST_PAGE_BUTTON = 'last-page-button',
  LOADING_PAGE = 'loading-page',
  LOADING_VIEW_DIALOG = 'loading-view-dialog',
  MOVE_ITEMS_BOTTOM_BUTTON = 'move-items-bottom-button',
  MOVE_ITEMS_DOWN_BUTTON = 'move-items-down-button',
  MOVE_ITEMS_NEXT_PAGE_BUTTON = 'move-items-next-page-button',
  MOVE_ITEMS_PREV_PAGE_BUTTON = 'move-items-prev-page-button',
  MOVE_ITEMS_TOP_BUTTON = 'move-items-top-button',
  MOVE_ITEMS_UP_BUTTON = 'move-items-up-button',
  NEXT_PAGE_BUTTON = 'next-page-button',
  PAGE_SIZE_SELECT = 'page-size-select',
  PAGE_SIZE_OF_TEN = 'page-size-of-ten',
  PAGE_SIZE_OF_TWENTY = 'page-size-of-twenty',
  PAGE_SIZE_OF_THIRTY = 'page-size-of-thirty',
  PAGE_SIZE_OF_FORTY = 'page-size-of-forty',
  PAGE_SIZE_OF_FIFTY = 'page-size-of-fifty',
  PREV_PAGE_BUTTON = 'prev-page-button',
  PERSONNEL_PAGE = 'personnel-page',
  UNAUTHORIZED_PAGE = 'unauthorized-page',
  VIEW_EDITOR_DRAWER_HEADER = 'view-editor-drawer-header',
  VIEWS_LIST = 'views-list',
  VIEWS_PAGE = 'views-page',
}

// Added delay offset to prevent this from going negative
const TOKEN_EXPIRE_TIME_MS = 203;
const TOKEN_EXPIRE_TIME_VALIDATION_DELAY_MS = 3_500;

const BEARER_AUTH_RESPONSE_SSO = {
  access_token: 'super_secret_access_token_SSO',
  expires_in: TOKEN_EXPIRE_TIME_MS,
  refresh_token: 'mega_fresh_refresh_token_SSO',
  token_type: 'bearer',
};

const BEARER_AUTH_RESPONSE_REFRESH = {
  access_token: 'super_secret_access_token_REFRESH',
  expires_in: TOKEN_EXPIRE_TIME_MS,
  refresh_token: 'mega_fresh_refresh_token_REFRESH',
  token_type: 'bearer',
};

const BEARER_AUTH_RESPONSE_REFRESH_TOKEN_TEST = {
  access_token: 'super_secret_access_token_REFRESH_TEST',
  expires_in: TOKEN_EXPIRE_TIME_MS,
  refresh_token: 'ah_so_refreshing',
  token_type: 'bearer',
};

const BEARER_AUTH_RESPONSE_REFRESH_TOKEN_TEST_REFRESHED = {
  access_token: 'super_secret_access_token_REFRESH_TEST_REFRESHED',
  expires_in: TOKEN_EXPIRE_TIME_MS,
  refresh_token: 'oh_wow_I_am_just_really_so_totally_refresh_right_now',
  token_type: 'bearer',
};

const INVALID_REFRESH_TOKEN = 'THIS_REFRESH_TOKEN_IS_INVALID';

const VALID_REFRESH_TOKEN_FOR_REFRESH_TEST = 'THIS_REFRESH_TOKEN_CAN_BE_REFRESHED';

const REFRESH_TOKEN_FOR_REFRESH_TRIGGER_TEST_ONLY = 'THIS_REFRESH_TOKEN_IS_ONLY_FOR_REFRESH_TRIGGER_TEST';

const TEST_SSO_TOKEN = 'THIS_IS_A_TEST_SSO_TOKEN';

export default {
  API_ERRORS,
  AUTHORIZATION_PAGE_MESSAGE,
  BASE_URL,
  BEARER_AUTH_RESPONSE_REFRESH,
  BEARER_AUTH_RESPONSE_REFRESH_TOKEN_TEST,
  BEARER_AUTH_RESPONSE_REFRESH_TOKEN_TEST_REFRESHED,
  BEARER_AUTH_RESPONSE_SSO,
  CLIENT_ID,
  ELEMENT_DATA_TEST_IDS,
  INVALID_REFRESH_TOKEN,
  REFRESH_TOKEN_FOR_REFRESH_TRIGGER_TEST_ONLY,
  TEST_SSO_TOKEN,
  TOKEN_EXPIRE_TIME_MS,
  TOKEN_EXPIRE_TIME_VALIDATION_DELAY_MS,
  VALID_REFRESH_TOKEN_FOR_REFRESH_TEST,
};
